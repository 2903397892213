<template>
  <a-modal
    :visible="visible"
    title="135编辑器"
    width="90%"
    :centered="true"
    :footer="null"
    :maskClosable="false"
    :keyboard="false"
    @cancel="$emit('update:visible', false)"
  >
    <iframe
      v-if="visible"
      src="plugins/135EditorDialogPage.html"
      id="135Iframe"
      frameborder="0"
      width="100%"
      :height="`${height - 150}px`"
    />
  </a-modal>
</template>

<script>
export default {
  name: 'Modal135',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      height: document.documentElement.clientHeight
    }
  }
}
</script>
